<template>
  <div class="has-nav-bar view-workticket">
    <van-nav-bar
      title="我的作业许可"
      fixed
      left-arrow
      @click-left="$router.back()"
    >
      <template #right>
        <van-icon
          v-if="btnAuth.add"
          size="24"
          name="iconfont-xinzengfengxian"
          class-prefix="iconfont"
          class="iconfont-xinzengfengxian"
          @click="addWorkticket()"
        />
      </template>
    </van-nav-bar>
    <van-tabs v-model="active" swipeable>
      <van-tab title="待处理"></van-tab>
      <van-tab title="与我相关"></van-tab>
    </van-tabs>
    <LoadListView
      v-model="loadStatus"
      :list="list"
      :total="total"
      @load="getList(false)"
    >
      <WorkticketItem
        v-for="(item, index) in list"
        :id="item.id"
        :key="item.id"
        :class="{ 'block-space_8': index < list.length - 1 }"
        :title="item.name"
        :status="item.statusName"
        :forced-finish="item.forcedFinish"
        :types="item.workType"
        :number="item.code"
        :start-time="item.planStart"
        :end-time="item.planEnd"
        :text1="item.departmentName"
        :text2="item.facilityName"
        :text3="item.supervisorList"
        :text4="item.approverList"
        :display-terminal-time="item.displayTerminalTime"
        :terminal-time="item.terminalTime"
        :alarm-num="item.alarmNum"
        :warning-flag="item.warningFlag"
        :item="item"
      />
    </LoadListView>
  </div>
</template>

<script>
import WorkticketItem from "@/components/workticket/WorkticketItem";
import { getWorkticketListMine, getWorkticketListTodo } from "@/api/workticket";
import LoadListView from "@/components/LoadListView";
import { mapState } from "vuex";
export default {
  name: "WorkticketMine",
  components: { LoadListView, WorkticketItem },
  data() {
    return {
      active: 0,
      loadStatus: "loading",
      list: [],
      total: 0,
      query: {
        size: 10,
        page: 1
      },
      error: false
    };
  },
  watch: {
    active(newValue, oldValue) {
      this.list = [];
      this.getList(true);
    }
  },
  computed: {
    ...mapState({
      btnAuth: state => state.menu.btnAuth
    })
  },
  created: function() {},
  mounted: function() {
    this.getList();
  },
  methods: {
    getList(refresh = false) {
      if (refresh) {
        this.query.page = 1;
        this.list = [];
        this.total = 0;
        this.loadStatus = "loading";
      }

      let params = this.active == 0 ? null : { ...this.query };

      if (this.active == 0) {
        //待处理没有分页
        getWorkticketListTodo()
          .then(list => {
            list = list.map(i => {
              if (
                i.displayTerminalTime &&
                i.terminalTime &&
                typeof i.terminalTime === "string"
              ) {
                let t = Date.parse(i.terminalTime) - Date.now();
                i.terminalTime = t > 0 ? t : 0;
              }
              return i;
            });
            this.list = this.list.concat(list);
            this.total = this.list.length;
            this.loadStatus = "success";
          })
          .catch(error => {
            console.log(error);
            this.loadStatus = "error";
          });
      } else {
        getWorkticketListMine(params)
          .then(({ total, list }) => {
            list = list.map(i => {
              if (
                i.displayTerminalTime &&
                i.terminalTime &&
                typeof i.terminalTime === "string"
              ) {
                let t = Date.parse(i.terminalTime) - Date.now();
                i.terminalTime = t > 0 ? t : 0;
              }
              return i;
            });
            this.list = this.list.concat(list);
            this.total = total;
            this.query.page++;
            this.loadStatus = "success";
          })
          .catch(error => {
            console.log(error);
            this.loadStatus = "error";
          });
      }
    },
    addWorkticket() {
      this.$router.push("/workticket/add");
    }
  }
};
</script>
